.row {
  display: flex;
  font-family: 'ClearSans-Regular';
}

.row-letter {
  margin: 2px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 4px;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 87);
  color: white;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  background-color: #e9c601;
  color: white;
}

.letter-absent {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white;
}

@media (prefers-color-scheme: dark) {
  .row-letter {
    border: 2px solid rgba(255, 255, 255, 0.4);
  }

  .letter-correct {
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: white;
  }

  .letter-elsewhere {
    border: 2px dotted rgba(0, 0, 0, 0.3);
    color: white;
  }

  .letter-absent {
    border: 2px solid transparent;
    background-color: rgb(142, 142, 142);
    color: white;
  }
}

@keyframes flip-in {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-90deg);
  }
}

@keyframes flip-out {
  from {
    transform: rotateX(-90deg);
  }
  to {
    transform: rotateX(0deg);
  }
}

@keyframes flip {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.flip-in {
  animation: flip-in 250ms;
  animation-fill-mode: forwards;
}

.flip-out {
  animation: flip-out 250ms;
  animation-fill-mode: forwards;
}

.bump {
  animation: bump 300ms ease-out;
}

.flip {
  animation: flip 600ms;
  animation-iteration-count: infinite;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
