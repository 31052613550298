.pill-button {
  border: 1px solid black;
  font-family: 'ClearSans-Regular';
  border-radius: 16px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.pill-button.dark {
  color: #e0e0e0;
  border: 1px solid #e0e0e0;
}
