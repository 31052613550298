.modal-box {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content img {
  max-width: 80px;
  margin-bottom: 20px;
}

.modal-content h2 {
  font-size: 50px;
  font-family: 'Teen-Bold', sans-serif;
  margin-top: 0px;
  margin-bottom: 12px;
}

.modal-content p {
  font-size: 18px;
  margin: 0px 30px 36px 30px;
  font-family: 'ClearSans-Regular';
}

.button-container {
  display: flex;
  gap: 10px;
  width: 100%;
}

.button-container div {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

@media screen and (max-width: 600px) {
  .modal-box {
    width: 80%;
  }

  .button-container {
    flex-direction: column;
  }

  .button-container div {
    flex-grow: 0;
    width: 100%;
  }
}
