.give-up-button {
  flex: 0;
  background: none;
  border: none;
  padding: 0;
  font-family: 'ClearSans-Regular';
  text-decoration: underline;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .give-up-button {
    color: #e0e0e0;
  }
}
