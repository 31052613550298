.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 10px;
  align-items: center;
  text-align: center;
}

.icons {
  display: flex;
  justify-content: flex-end;
}

.container button {
  height: 32px;
  width: 32px;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
}

.logo a {
  font-family: 'Teen-Bold', sans-serif;
  text-decoration: none;
  color: black;
  font-size: 30px;
}

@media (prefers-color-scheme: dark) {
  .logo a {
    color: #e0e0e0;
  }

  .icons svg {
    fill: #e0e0e0;
  }
}
