ul.contributors {
  list-style: none;
  padding-left: 0;
}

ul.contributors li a {
  font-style: italic;
  color: rgb(71, 122, 170);
  text-decoration: none;
}

ul.instructions {
  padding-left: 20px;
}
