.container {
  border-top: 1px solid;
  border-bottom: 1px solid;
  text-align: left;
  font-family: 'ClearSans-Regular';
}

.container > p {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.list {
  list-style: none;
  padding-left: 0px;
  margin-top: 0px;
}

.list > li {
  counter-increment: item;
  margin-bottom: 10px;
  font-weight: lighter;
  font-size: 14px;
}

.word {
  text-transform: capitalize;
}
