.mofi-container {
  max-width: 500px;
  position: fixed;
  transform: translate(-50%);
  z-index: 3;
  left: 50%;
  top: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 90%;
  user-select: none;
  cursor: pointer;
}

.mofi {
  position: relative;
  margin-top: 100px;
  left: -60px;
  animation: swing ease-in-out 1s infinite alternate;
  height: 70%;
}

.confetti {
  position: fixed !important;
}

.greeting-message {
  text-align: left;
}

.close-message {
  font-size: 12px;
  text-align: end;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
