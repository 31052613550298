.content {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 50px;
}

.numbers-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 14px;
}

.number-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number-label-qty {
  font-size: 46px;
}

.number-label-text {
  font-size: 13px;
  max-width: 60px;
  text-align: center;
}

.dist-bar-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  font-size: 12px;
}

.dist-bar-fill {
  background-color: rgb(193, 206, 93);
  text-align: end;
  padding-right: 5px;
  border-radius: 3px;
  transition: width 1s;
  transition-timing-function: ease-out;
}

.logo-container {
  text-align: center;
}

.logo-container h2 {
  font-size: 36px;
  font-family: 'Teen-Bold', sans-serif;
  margin: 8px;
}

.logo-container img {
  width: 56px;
  height: 56px;
}

@media screen and (max-width: 500px) {
  .content {
    padding: 8vw;
    width: 90%;
    max-width: none;
    max-height: none;
  }

  .number-label-qty {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: 80%;
  }
}
