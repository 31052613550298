.bubble {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  width: 290px;
  background: #fff;
  border-radius: 40px;
  padding: 24px;
  text-align: justify;
  color: #000;
}

.bubble-bottom-left:before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-top: 12px solid #fff;
  border-bottom: 20px solid transparent;
  left: 45%;
  bottom: -24px;
}
