div.share-container {
  margin: 20px;
  display: flex;
  justify-content: center;
}

button.share-button {
  width: fit-content;
  padding: 2px 10px;
}

button.share-button span {
  padding-top: 3px;
  letter-spacing: 1px;
}

button.share-button img {
  height: 45%;
}

button.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
