.hat {
  position: absolute;
  z-index: 2;
  height: 113px;
  top: -78px;
  transform: rotate(-20deg);
  left: 100px;
}

.mofi {
  height: 300px;
}
