@font-face {
  font-family: 'ClearSans-Regular';
  src:
    local('ClearSans-Regular'),
    url('./fonts/ClearSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ClearSans-Italic';
  src:
    local('ClearSans-Italic'),
    url('./fonts/ClearSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ClearSans-Bold';
  src:
    local('ClearSans-Bold'),
    url('./fonts/ClearSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Teen-Bold';
  src:
    local('Teen-Bold'),
    url('./fonts/teen.bold.otf') format('opentype');
  font-weight: normal;
  font-style: bold;
}

* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
  font-family: 'ClearSans-Regular';
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.App-container h1 {
  margin-top: 0;
}

.Game {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #404040;
    color: #e0e0e0;
  }

  .Game-keyboard-button {
    color: #404040;
  }
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  margin-inline-start: 0.5rem;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}
