.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: black;
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 520px;
  max-height: 100%;
  overflow: auto;
  font-family: 'ClearSans-Regular', sans-serif;
  text-align: left;
}

.content h2 {
  font-size: 28px;
  margin-bottom: 4px;
}

.content h3 {
  font-size: 20px;
  margin-top: 0px;
  font-weight: normal;
}

.content h4 {
  font-size: 16px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .content {
    max-width: 90%;
    max-height: 80vh;
    overflow: auto;
  }
}
